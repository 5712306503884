@import 'config/variables';
@import 'components/typography/hook';
@import 'utils/font-family';

$typography_headline-font-family: $font_family-primary !default;
$typography_headline-font-weight: 700 !default;
$typography_headline-color: $color_text-800 !default;
$typography_headline-text-transform: uppercase !default;
$typography_headline-margins: 1rem 0 2rem !default;

$typography_link-color: $link-color_base !default;
$typography_link--hover-color: $link-color_base-hover !default;
$typography_link-text-decoration: none !default;
$typography_link--hover-text-decoration: $typography_link-text-decoration !default;

$typography_list-margin: 0 0 2.5rem !default;
$typography_list-padding: 0 0 0 4rem !default;

$typography_list-item-margin: 0 0 1rem !default;

@mixin headline($level: $font-size_headline-2) {
    @include font-family($typography_headline-font-family);

    font-size: $level;
    color: $typography_headline-color;
    font-weight: $typography_headline-font-weight;
    text-transform: $typography_headline-text-transform;
    margin: $typography_headline-margins;

    @include headline_hook($level);
}

@mixin link(
    $color: $typography_link-color,
    $color-hover: $typography_link--hover-color,
    $decoration: $typography_link-text-decoration,
    $decoration-hover: $typography_link--hover-text-decoration
) {
    &,
    &:visited {
        color: $color;
        text-decoration: $decoration;
    }

    &:hover,
    &:focus,
    &:active,
    &:visited:hover,
    &:visited:focus {
        color: $color-hover;
        text-decoration: $decoration-hover;
    }

    @include link_hook();
}

@mixin list() {
    margin: $typography_list-margin;
    padding: $typography_list-padding;

    @include list_hook();
}

@mixin list-item() {
    margin: $typography_list-item-margin;

    @include list-item_hook();
}
